<template>
  <div :class="['shared-kit-tooltip', classes]" @mouseenter="handleEnter" @mouseleave="handleLeave">
    <div ref="tooltip" class="shared-kit-tooltip__content">
      <slot />
    </div>
    <div class="shared-kit-tooltip__preview">
      <slot name="preview-icon">
        <LazySvgoQuestionIcon class="shared-kit-tooltip__preview-icon" />
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ISharedKitTooltipProps } from './SharedKitTooltip.types';
import { OBSERVATION_PROCENT, POSITIONS } from './SharedKitTooltip.data';

const props = defineProps<ISharedKitTooltipProps>();
const { position, debounceInMs } = toRefs(props);

const tooltip = ref<HTMLDivElement>();

const prefferedPosition = ref(position.value);
const temporaryPositionIndex = ref(POSITIONS.indexOf(prefferedPosition.value));

const showContent = ref(false);

const classes = computed(() => ({
  [`shared-kit-tooltip--${prefferedPosition.value}`]: true,
  'shared-kit-tooltip--active': showContent.value,
}));

watch(position, (newPosition) => {
  prefferedPosition.value = newPosition;
  temporaryPositionIndex.value = POSITIONS.indexOf(newPosition);
});

let observer: IntersectionObserver;
let debounceTimeout: ReturnType<typeof setTimeout>;

const handleEnter = () => {
  if (!tooltip.value) return;

  if (debounceTimeout) clearTimeout(debounceTimeout);

  observer = new IntersectionObserver(([tooltipEntry]) => {
    const percentage = tooltipEntry.intersectionRatio;
    observer.disconnect();

    const isVisible = percentage > OBSERVATION_PROCENT;

    if (!isVisible) {
      temporaryPositionIndex.value = (temporaryPositionIndex.value + 1) % POSITIONS.length;
      prefferedPosition.value = POSITIONS[temporaryPositionIndex.value];
    }

    const isPrefferedPositionSame = prefferedPosition.value === position.value;

    if (isVisible || isPrefferedPositionSame) {
      showContent.value = true;
      return;
    }

    handleEnter();
  });

  observer.observe(tooltip.value);
};

const leaveAction = () => {
  showContent.value = false;

  if (!observer) return;
  observer.disconnect();
};

const handleLeave = () => {
  if (!debounceInMs.value) {
    leaveAction();
    return;
  }

  if (debounceTimeout) clearTimeout(debounceTimeout);
  debounceTimeout = setTimeout(leaveAction, debounceInMs.value);
};
</script>

<style scoped lang="scss" src="./SharedKitTooltip.scss" />
